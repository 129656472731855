.privilege {
  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;

    @media (--xl) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--sm) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    padding: 20px 40px;
    border-radius: var(--border-radius);
    transition: all .5s ease;

    &:hover {
      transform: scale(1.05);
    }

    &:nth-child(2n) {
      background: #E3E8FF;
    }

    & > img {
      object-fit: contain;
      height: 110px;
      margin-bottom: 10px;

      @media (--lg) {
        height: 90px;
      }
    }
    & > h4 {
      font-weight: 600;
      font-size: var(--fz-h4);
      margin-bottom: 10px;
    }
    & > p {
      font-weight: 400;
    }

    @media (--lg) {
      padding: 20px 26px;
    }
    @media (--sm) {
      text-align: center;
    }
  }
}