.garant {
  &__head {
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    & > li {
      width: 31%;
      & > input[type=radio] {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      & > label {
        display: block;
        padding: 25px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        border: 2px solid #EEEEEE;
        border-radius: var(--border-radius);
        cursor: pointer;
        user-select: none;
        transition: all .4s ease;
        &:hover {
          border: 2px solid var(--color-blue);
        }

        @media (--lg) {
        padding: 20px;
        }
        @media (--md) {
          font-size: 16px;
        }
      }
      /* Checked */
      & > input[type=radio]:checked + label {
        border: 2px solid var(--color-blue-light);
      }
    }
    &-mob {
      display: none;

      @media (--sm) {
        display: block;
        width: 50%;
        font-weight: 600;
        font-size: 16px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 30px;
      }
      @media (--xs) {
        width: 75%;
      }
    }
    @media (--sm) {
      display: none;
    }
  }
}

.case {
  display: none;
  opacity: 0;
  visibility: hidden;
  &.active {
    display: flex;
    opacity: 1;
    visibility: visible;
    transition: all .5s ease-in-out;
    /* animation: show_screen 1.2s ease-in-out; */
  }
  
  &__left {
    flex-basis: 50%;
    margin-right: 40px;
    & > img {
      object-fit: contain;
      width: 100%;
    }
    @media (--lg) {
      margin-right: 30px;
    }
    @media (--sm) {
      display: none;
    }
  }
  &__right {
    --fz-uslugi-h2: 28px;

    flex-basis: 50%;
    @media (--xl) {
      --fz-uslugi-h2: 24px;
    }
    @media (--lg) {
      flex-basis: 46%;
    }
    @media (--sm) {
      flex-basis: 100%;
      --fz-uslugi-h2: 20px;
    }
  }

  &__usluga {
    margin-bottom: 15px;
    & > span {
      display: inline-block;
      font-weight: 600; 
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #B5B8C0;
      margin-bottom: 10px;
    }
    & > h2 {
      font-weight: 700;
      font-size: var(--fz-uslugi-h2);
      color: #424242;
    }
    @media (--sm) {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  &__img {
    display: none;

    & > img {
      object-fit: contain;
      width: 100%;
    }
    @media (--sm) {
      display: block;
      width: 60%;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
    }
    @media (--xs) {
      width: 85%;
    }
  }
  &__price {
    display: flex;
    flex-wrap: wrap;
    max-width: 520px;

    &-item {
      display: flex;
      flex-direction: column;
      padding: 15px 20px;
      margin-bottom: 15px;

      & span {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #B5B8C0;
        margin-bottom: 10px;

        @media (--lg) {
          margin-bottom: 3px;
        }
        @media (--md) {
          font-size: 12px;
        }
      }
      & strong {
        display: inline-block;
        font-weight: 700;
        font-size: var(--fz-uslugi-h2);
        text-transform: uppercase;

        color: #343D58;

        &.light {
          font-weight: 400;
        }
      }
    }
    &-price {
      width: 160px;
      background: #F1F3F8;
      border-radius: var(--border-radius);
      margin-right: 25px;

      @media (--xl) {
        width: auto;
      }
    }
    &-sale {
      max-width: 345px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      border: 2px solid #EEEEEE;
      border-radius: var(--border-radius);

      &-comb {
        display: flex;
        flex-direction: column;
        margin-right: 25px;
      }
      & > p {
        font-weight: 700;
      }
    }
    &-ok {
      align-items: center;
      flex-direction: row;
      line-height: 1.2;
      background: #F9FAFF;
      border-radius: var(--border-radius);    
      margin-bottom: 25px;

      &:before {
        content: '';
        display: block;
        width: 25px;
        height: 15px;
        background: url('../img/uslugi/ok_blue.svg') center/contain no-repeat;
        margin-right: 35px;
      }
    }
  }
  &__includes {
    margin-bottom: 60px;
    & > h4 {
      font-weight: 700;
      font-size: var(--fz-uslugi-h2);
      line-height: 32px;
      margin-bottom: 20px;
    }

    & li {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 12px;
      padding-left: 32px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        display: inline-block;
        width: 18px;
        height: 13px;
        background: url('../img/uslugi/ok_green.svg') center/contain no-repeat;
      }
    }

    @media (--xl) {
      margin-bottom: 40px;
    }
  }
  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-phone {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      & > span {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #B5B8C0;
        margin-bottom: 5px;

        @media (--md) {
          font-size: 12px;
        }
      }
      & > a {
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        letter-spacing: 0.1em;
        transition: all .5s ease;

        &:hover {
          opacity: 0.9;
        }

        @media (--md) {
          font-size: 18px;
        }
      }
    }
  }
}


/* SELECT COMPONENT */
.my-select {
  /* reset */
  border: none;
  margin: 0;
  width: 100%;
  outline: none;
  cursor: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* style */
  display: inline-block;
  background-image: linear-gradient(45deg, transparent 50%, #424242 50%),
    linear-gradient(135deg, #424242 50%, transparent 50%);
  background-position: calc(100% - 18px) calc(1em + 11px),
  calc(100% - 10px) calc(1em + 11px);
  background-size: 6px 6px, 9px 6px;
  background-repeat: no-repeat;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 2px solid var(--color-blue-light);
  color: var(--color-black);
  font: inherit;
  text-align: center;
  line-height: 1.5em;
  padding: 19px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &::-ms-expand {
    display: none;
  }
  &:hover {
    opacity: 0.8;
  }
}