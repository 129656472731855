@import "./00_navbar.pcss";
@import "./01_header.pcss";
@import "./02_garant.pcss";
@import "./03_steps.pcss";
@import "./04_photos.pcss";
@import "./05_studytime.pcss";
@import "./06_sale.pcss";
@import "./07_privilege.pcss";
@import "./08_maps.pcss";
@import "./09_faq.pcss";
@import "./10_footer.pcss";
