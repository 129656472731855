.steps {
  --z-10: 10;
  --z-5: 5;
  --z-0: -1;

  &__body {
    height: 100%;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 160px 180px 186px;
    gap: 100px 5%;

    @media (--xl) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
      gap: 5% 5%;
    }
    @media (--xs) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto-fill, max-content);
      gap: 20px;
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #EDEDED;
      border-radius: var(--border-radius);
      font-weight: 500;
      padding: 10px 25px;
      transition: all .5s ease;

      &:hover {
        transform: scale(1.05);
      }

      & > span {
        font-weight: 600;
        text-align: right;
        color: #B5B8C0;
        z-index: var(--z-10);

      }
      & > h4 {
        font-size: 28px;
        z-index: var(--z-10);

        @media (--xl) {
          font-size: 24px;
        }
        @media (--sm) {
          font-size: 20px;
        }
        @media (--xs) {
          font-size: 18px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -5px;
        width: 103%;
        height: 75%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        border-radius: var(--border-radius);
      }

      &:nth-child(1) {
        background: url(../img/steps/1.png) left/contain no-repeat;
      }
      &:nth-child(2) {
        background: url(../img/steps/2.png) left/contain no-repeat;
      }
      &:nth-child(3) {
        background: url(../img/steps/3.png) left/contain no-repeat;
      }
      &:nth-child(4) {
        background: url(../img/steps/4.png) center/contain no-repeat;
      }
      &:nth-child(5) {
        background: url(../img/steps/5.png) center/contain no-repeat;
      }
      &:nth-child(6) {
        background: url(../img/steps/6.png) left/contain no-repeat;
      }
      &:nth-child(7) {
        background: url(../img/steps/7.png) left/contain no-repeat;
      }

      &-spec {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;

        background: #FAFBFF;
        border: 1px solid #EDEDED;
        border-radius: var(--border-radius);
        padding: 20px 25px;
        z-index: 0;

        font-weight: 600;
        font-size: var(--fz-h4);

        @media (--xl) {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 5;
          grid-row-end: 6;

          text-align: center;
        }
        @media (--xs) {
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 8;
          grid-row-end: 9;

          padding: 20px 15px;
        }

        & > h3 {
          margin-bottom: 15px;
        }
        &-btn {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;

          & > p {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: var(--fz-h4);
            color: #B5B8C0;
            text-align: right;
            margin-left: 50px;

            &:before {
              content: '';
              display: block;
              width: 70px;
              height: 10px;
              background: url('../img/steps/left.svg') center/contain no-repeat;
              margin-right: 35px;

              @media (--sm) {
                order: 2;
                width: 20px;
                height: 16px;
                background: url('../img/steps/down.svg') center/contain no-repeat;
                margin-right: 0;
                margin-top: 10px;
              }
            }

            @media(--xl) {
              display: none;
            }
            @media (--lg) {
              display: flex;
              width: 50%;
            }
            @media (--sm) {
              flex-direction: column;
              order: -1;
              text-align: center;
              font-weight: 400;
              font-size: 14px;
              margin-left: 0;
              margin-bottom: 20px;
            }
          }
          @media (--sm) {
            width: 100%;
            flex-direction: column;
          }
        }
      }

      @media (--xs) {
        min-height: 130px;
      }
    }
  }
}