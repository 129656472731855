.modal {
  position: relative;
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  cursor: pointer;

  &::after{
    content: "";
    position: absolute;
    top: 2%;
    right: 5%;
    display: inline-block;
    width: 30px;
    height: 30px;
    z-index: 100;
    background: url(../img/close.svg) 50%/contain no-repeat;
  }

  &__container {
    width: 40vw; 
    max-width: 650px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);   
    border-radius: calc(var(--border-radius) * 2);
    border: 1px solid rgba(122, 118, 229, 0.5);
    background-color: #fefefe;
    cursor: auto;

    @media (--md) {
      width: 80%;
    }
    @media (--xs) {
      width: 90%;
    }
  }

  &__body {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 30px;
    border-radius: 10px;
    border: 1px dashed rgba(0, 0, 0, 0.08);
    text-align: center;

    @media (--sm) {
      padding: 25px 15px;
    }
  }

  &__head {
    color: var(--color-blue);
    & > h2  {
      font-size: var(--fz-h2);
      font-weight: 600;
    }
    & > p {
      margin-top: 20px;
    }
  }

  &__main{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: var(--color-blue);
    gap: 20px;
    
    & > hr {
      border:0;
      border-top: 1px solid var(--color-blue);
      margin: 0;
      width: 80%;
      height: 2px;
    }
    & > p {
      font-size: var(--fz-h4);
      font-weight: 400;
      text-align: center;
    }
  }

  &__form {
    width: min-content;

    & > .input {
      width: 88%;
      margin-bottom: 20px;
    }
    & > p {
      margin-top: 20px;
      font-size: 14px;
      color: #C0C0C0;
      & > a {
        text-decoration: underline;
      }

      @media (--sm) {
        font-size: 12px;
      }
    }
  }
}