.footer {
  &__body {
    display: flex;
    justify-content: space-between;

    @media (--xs) {
      flex-direction: column;
    }
  }
  &__links {
    margin-right: 8%;
    font-weight: 600;
    font-size: 20px;
    & > img {
      object-fit: contain;
      width: 150px;
      height: 90px;
      margin-bottom: 15px;
    }
    @media (--xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  &__messengers {
    & > h4 {
      margin-bottom: 10px;
    }
    @media (--xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > ul {
      &:not(:last-child) {
        margin-right: 50px;
        @media (--xs) {
          margin-right: 0;
        }
      }

      & > li {
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 10px;
        & > a {
          transition: all .5s ease;
          &:hover {
            color: var(--color-gray);
          }
        }

        &:first-child {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 25px;
        }
      }
    }

    @media (--xs) {
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  &__madeby {
    margin-top: 50px;
    font-weight: 400;
    color: #B5B8C0;

    @media (--xs) {
      text-align: center;
    }

    & > a {
      transition: all .5s ease;

      &:hover {
        color: var(--color-black);
      }
    }
  }
}