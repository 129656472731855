.maps {
  &__container {
    padding-bottom: var(--container-padding-h);
  }
  &__body {
    position: relative;
  }

  &__map {
    height: 496px;

    & > iframe {
      width: 100%;
      height: 100%;
    }

    @media (--sm) {
      height: 350px;
    }
    @media (--xs) {
      height: 190px;
    }
  }

  &__info {

  }
}

.infomap {
  position: absolute;
  top: 48px;
  left: 15%;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid #EDEDED;
  line-height: 1.4;
  padding: 40px 70px;

  & > h4 {
    font-weight: 600;
    font-size: var(--fz-h4);
    text-align: center;
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    
    &:last-child {
      margin-bottom: 0;
      line-height: 1.1;
    }
    @media (--xs) {
      width: 190px;
      margin-bottom: 15px;
    }
  }
  &__text {
    margin-left: 15px;
    @media (--xs) {
      margin-left: 30px;
    }
  }

  @media (--lg) {
    padding: 30px 50px;
  }
  @media (--md) {
    left: 3%;
  }
  @media (--sm) {
    position: relative;
    top: auto;
    left: auto;
    margin-top: 15px;

    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (--xs) {
    margin-left: var(--container-padding-w);
    margin-right: var(--container-padding-w);
  }
}