@import "https://cdn.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, input {
  font: inherit;
  color: inherit;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding: 0;
}

a:active, a:hover, a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

::-webkit-search-decoration {
  display: none;
}

.navpanel {
  --h_nav: 72px;
}

@media (max-width: 650px) {
  .navpanel {
    --h_nav: 60px;
  }
}

.navpanel__container {
  max-width: var(--content-width);
  padding-left: var(--container-padding-w);
  padding-right: var(--container-padding-w);
  height: var(--h_nav);
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.navpanel__logo {
  flex-direction: column;
  align-items: flex-end;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  display: flex;
}

.navpanel__logo img {
  object-fit: contain;
  width: 170px;
  height: var(--h_nav);
  margin-bottom: 5px;
}

@media (max-width: 1100px) {
  .navpanel__logo img {
    width: 130px;
  }
}

@media (max-width: 480px) {
  .navpanel__logo img {
    width: 110px;
  }
}

.navpanel__address {
  height: 100%;
  display: flex;
}

.navpanel__address > img {
  width: 42px;
  height: 42px;
  margin-right: 15px;
}

.navpanel__address-right {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.navpanel__address-right > span {
  color: var(--color-gray);
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}

.navpanel__address-right > p {
  font-weight: 600;
}

.navpanel__address-right > a {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--color-gray);
  border-bottom: 1px dashed var(--color-gray);
  margin-top: 5px;
  font-size: 11px;
  transition: all .3s ease-in;
  display: inline;
}

.navpanel__address-right > a:hover {
  color: var(--color-black);
  border-bottom: 1px dashed var(--color-black);
}

@media (max-width: 1100px) {
  .navpanel__address {
    display: none;
  }
}

.navpanel__mess {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.navpanel__mess > span {
  font-size: 12px;
  line-height: 1.5;
}

.navpanel__mess > span:before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #15c515;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

@media (max-width: 480px) {
  .navpanel__mess > span {
    position: absolute;
    top: 6px;
  }
}

@media (max-width: 650px) {
  .navpanel__mess {
    order: 1;
  }
}

@media (max-width: 480px) {
  .navpanel__mess {
    order: 0;
  }
}

.navpanel__phone {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.navpanel__phone > span {
  color: var(--color-gray);
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-bottom: 9px;
  font-size: 10px;
  font-weight: 600;
}

.navpanel__phone > a {
  font-weight: 600;
  font-size: var(--fz-h4);
  transition: all .4s;
}

.navpanel__phone > a:hover {
  opacity: .9;
}

@media (max-width: 480px) {
  .navpanel__phone {
    display: none;
  }
}

.navpanel__cta {
  color: var(--color-white);
  background: #e84b4b;
  border-radius: 5px;
  align-items: center;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  transition: all .5s;
  display: flex;
}

.navpanel__cta > img {
  margin-right: 10px;
}

.navpanel__cta:hover {
  -ms-transform: scale(.95);
  transform: scale(.95);
}

@media (max-width: 768px) {
  .navpanel__cta {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .navpanel__cta {
    display: none;
  }
}

.navpanel__mobile {
  --w_burger: 47px;
  --h_burger: 34px;
  display: none;
}

@media (max-width: 480px) {
  .navpanel__mobile {
    width: var(--w_burger);
    display: block;
  }
}

.navpanel__burger {
  width: var(--w_burger);
  height: var(--h_burger);
  display: flex;
}

.navpanel__burger > input[type="checkbox"] {
  display: none;
}

.navpanel__burger > input[type="checkbox"] + label {
  width: var(--w_burger);
  height: var(--h_burger);
  cursor: pointer;
  transition: all .4s;
}

.navpanel__burger img {
  object-fit: contain;
  width: 100%;
}

.navpanel__menu {
  display: none;
}

@media (max-width: 480px) {
  .navpanel__menu {
    height: 0;
    width: 100%;
    opacity: 1;
    z-index: 100;
    background-color: #f5f5f6;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    transition: all .5s .2s;
    display: flex;
    position: absolute;
    top: 100%;
    overflow: hidden;
  }
}

.navpanel__menu > p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.navpanel__menu > ul {
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 25px;
  display: flex;
}

.navpanel__menu > ul > li > a {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: var(--fz);
  border-radius: var(--border-radius);
  padding: 5px 10px;
  transition: all .4s;
  display: block;
}

.navpanel__menu > ul > li > a:hover, .navpanel__menu > ul > li > a:focus {
  color: var(--color-blue-light);
}

.navpanel__menu > .navpanel__phone {
  height: auto;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.navpanel__menu > .navpanel__cta {
  display: flex;
}

.header__container {
  padding-bottom: var(--container-padding-h);
}

.header__nav {
  background: #f9fafc;
  margin-top: 25px;
}

.header__nav > ul {
  max-width: var(--content-width);
  padding: 20px var(--container-padding-w);
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.header__nav > ul > li {
  letter-spacing: .05em;
  color: #9f9fa1;
  font-weight: 600;
  line-height: 1.5;
}

.header__nav > ul > li > a {
  transition: all .5s;
}

.header__nav > ul > li > a:hover {
  color: var(--color-black);
}

.header__nav > ul > li:not(:last-child) {
  margin-right: 60px;
}

@media (max-width: 768px) {
  .header__nav > ul > li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 480px) {
  .header__nav {
    display: none;
  }
}

.header__main {
  position: relative;
}

.header__main:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url("bg.d5842fec.png") center / cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.header__main:after {
  content: "";
  width: 100%;
  height: 270px;
  z-index: 0;
  background: url("smoke.ee992ad3.png") center / contain repeat-x;
  position: absolute;
  bottom: -100px;
  left: 0;
}

@media (max-width: 768px) {
  .header__main:after {
    height: 190px;
    background: url("smoke.ee992ad3.png") center / cover repeat-x;
    bottom: -50px;
  }
}

@media (max-width: 650px) {
  .header__main:after {
    height: 250px;
    bottom: 0;
  }
}

@media (max-width: 480px) {
  .header__main:after {
    height: 200px;
    bottom: 0;
  }
}

.header__main-wrapper {
  width: 100%;
  max-width: var(--content-width);
  padding-left: var(--container-padding-w);
  padding-right: var(--container-padding-w);
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

@media (max-width: 650px) {
  .header__main-wrapper {
    flex-direction: column;
  }
}

.header__main-left {
  flex-basis: 50%;
  margin-top: 80px;
  margin-bottom: 120px;
}

.header__main-left > h1 {
  font-weight: 600;
  font-size: var(--fz-h2);
  margin-bottom: 40px;
  line-height: 1.1;
}

@media (max-width: 650px) {
  .header__main-left > h1 {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .header__main-left > h1 {
    text-align: center;
  }
}

.header__main-left > h1 > strong {
  color: #fff;
  margin-left: 10px;
  padding: 2px 8px;
  font-size: .8em;
  display: inline-block;
  position: relative;
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
  box-shadow: 4px 4px 8px rgba(34, 60, 80, .2);
}

.header__main-left > h1 > strong:before {
  content: "";
  display: inherit;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #e84b4b;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 650px) {
  .header__main-left > .btn {
    display: none;
  }
}

@media (max-width: 900px) {
  .header__main-left {
    margin-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .header__main-left {
    flex-basis: 60%;
  }
}

@media (max-width: 650px) {
  .header__main-left {
    flex-basis: 100%;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.header__main-right {
  max-width: 820px;
  width: 70%;
  flex-basis: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.header__main-right > img {
  object-fit: contain;
  width: 100%;
}

.header__main-right > .btn {
  display: none;
}

@media (max-width: 650px) {
  .header__main-right > .btn {
    display: inline-block;
  }
}

@media (max-width: 1100px) {
  .header__main-right {
    width: 65%;
    top: 80px;
  }
}

@media (max-width: 900px) {
  .header__main-right {
    top: auto;
    bottom: 10px;
  }
}

@media (max-width: 768px) {
  .header__main-right {
    bottom: 40px;
  }
}

@media (max-width: 650px) {
  .header__main-right {
    width: 100%;
    text-align: center;
    position: relative;
  }
}

.header__utp {
  position: relative;
}

.header__utp > ul {
  max-width: var(--content-width);
  padding-left: var(--container-padding-w);
  padding-right: var(--container-padding-w);
  z-index: 10;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.header__utp > ul > li {
  border-radius: var(--border-radius);
  background: #fff;
  border: 1px solid #ededed;
  flex-direction: column;
  align-items: flex-start;
  padding: 19px 25px;
  display: flex;
}

.header__utp > ul > li > img {
  object-fit: contain;
  height: 62px;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .header__utp > ul > li > img {
    height: 50px;
  }
}

.header__utp > ul > li > span {
  font-weight: 600;
  font-size: var(--fz-h4);
  margin-bottom: 10px;
}

.header__utp > ul > li > p {
  font-weight: 400;
}

@media (max-width: 768px) {
  .header__utp > ul > li {
    padding: 18px;
  }
}

@media (max-width: 650px) {
  .header__utp > ul > li {
    text-align: center;
    align-items: center;
  }

  .header__utp > ul {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.header__utp:before {
  content: "";
  width: 100%;
  height: 195px;
  z-index: -1;
  background: #fafbff;
  position: absolute;
  top: 50%;
  left: 0;
}

@media (max-width: 650px) {
  .header__utp:before {
    height: 90px;
    top: auto;
    bottom: -60px;
  }
}

.garant__nav {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
}

.garant__nav > li {
  width: 31%;
}

.garant__nav > li > input[type="radio"] {
  opacity: 0;
  width: 0;
  position: fixed;
}

.garant__nav > li > label {
  text-align: center;
  border-radius: var(--border-radius);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #eee;
  padding: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  transition: all .4s;
  display: block;
}

.garant__nav > li > label:hover {
  border: 2px solid var(--color-blue);
}

@media (max-width: 900px) {
  .garant__nav > li > label {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .garant__nav > li > label {
    font-size: 16px;
  }
}

.garant__nav > li > input[type="radio"]:checked + label {
  border: 2px solid var(--color-blue-light);
}

.garant__nav-mob {
  display: none;
}

@media (max-width: 650px) {
  .garant__nav-mob {
    width: 50%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
}

@media (max-width: 480px) {
  .garant__nav-mob {
    width: 75%;
  }
}

@media (max-width: 650px) {
  .garant__nav {
    display: none;
  }
}

.case {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.case.active {
  opacity: 1;
  visibility: visible;
  transition: all .5s ease-in-out;
  display: flex;
}

.case__left {
  flex-basis: 50%;
  margin-right: 40px;
}

.case__left > img {
  object-fit: contain;
  width: 100%;
}

@media (max-width: 900px) {
  .case__left {
    margin-right: 30px;
  }
}

@media (max-width: 650px) {
  .case__left {
    display: none;
  }
}

.case__right {
  --fz-uslugi-h2: 28px;
  flex-basis: 50%;
}

@media (max-width: 1100px) {
  .case__right {
    --fz-uslugi-h2: 24px;
  }
}

@media (max-width: 900px) {
  .case__right {
    flex-basis: 46%;
  }
}

@media (max-width: 650px) {
  .case__right {
    --fz-uslugi-h2: 20px;
    flex-basis: 100%;
  }
}

.case__usluga {
  margin-bottom: 15px;
}

.case__usluga > span {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #b5b8c0;
  margin-bottom: 10px;
  font-weight: 600;
  display: inline-block;
}

.case__usluga > h2 {
  font-weight: 700;
  font-size: var(--fz-uslugi-h2);
  color: #424242;
}

@media (max-width: 650px) {
  .case__usluga {
    text-align: center;
    margin-bottom: 30px;
  }
}

.case__img {
  display: none;
}

.case__img > img {
  object-fit: contain;
  width: 100%;
}

@media (max-width: 650px) {
  .case__img {
    width: 60%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media (max-width: 480px) {
  .case__img {
    width: 85%;
  }
}

.case__price {
  max-width: 520px;
  flex-wrap: wrap;
  display: flex;
}

.case__price-item {
  flex-direction: column;
  margin-bottom: 15px;
  padding: 15px 20px;
  display: flex;
}

.case__price-item span {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #b5b8c0;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}

@media (max-width: 900px) {
  .case__price-item span {
    margin-bottom: 3px;
  }
}

@media (max-width: 768px) {
  .case__price-item span {
    font-size: 12px;
  }
}

.case__price-item strong {
  font-weight: 700;
  font-size: var(--fz-uslugi-h2);
  text-transform: uppercase;
  color: #343d58;
  display: inline-block;
}

.case__price-item strong.light {
  font-weight: 400;
}

.case__price-price {
  width: 160px;
  border-radius: var(--border-radius);
  background: #f1f3f8;
  margin-right: 25px;
}

@media (max-width: 1100px) {
  .case__price-price {
    width: auto;
  }
}

.case__price-sale {
  max-width: 345px;
  width: 100%;
  border-radius: var(--border-radius);
  border: 2px solid #eee;
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

.case__price-sale-comb {
  flex-direction: column;
  margin-right: 25px;
  display: flex;
}

.case__price-sale > p {
  font-weight: 700;
}

.case__price-ok {
  border-radius: var(--border-radius);
  background: #f9faff;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  line-height: 1.2;
}

.case__price-ok:before {
  content: "";
  width: 25px;
  height: 15px;
  background: url("ok_blue.03a9816e.svg") center / contain no-repeat;
  margin-right: 35px;
  display: block;
}

.case__includes {
  margin-bottom: 60px;
}

.case__includes > h4 {
  font-weight: 700;
  font-size: var(--fz-uslugi-h2);
  margin-bottom: 20px;
  line-height: 32px;
}

.case__includes li {
  align-items: center;
  margin-bottom: 12px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.case__includes li:before {
  content: "";
  width: 18px;
  height: 13px;
  background: url("ok_green.b43a1844.svg") center / contain no-repeat;
  display: inline-block;
  position: absolute;
  left: 0;
}

@media (max-width: 1100px) {
  .case__includes {
    margin-bottom: 40px;
  }
}

.case__btn {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.case__btn-phone {
  flex-direction: column;
  margin-top: 20px;
  display: flex;
}

.case__btn-phone > span {
  text-align: center;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #b5b8c0;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .case__btn-phone > span {
    font-size: 12px;
  }
}

.case__btn-phone > a {
  text-align: center;
  letter-spacing: .1em;
  font-size: 22px;
  font-weight: 700;
  transition: all .5s;
}

.case__btn-phone > a:hover {
  opacity: .9;
}

@media (max-width: 768px) {
  .case__btn-phone > a {
    font-size: 18px;
  }
}

.my-select {
  width: 100%;
  cursor: inherit;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #424242 50%), linear-gradient(135deg, #424242 50%, rgba(0, 0, 0, 0) 50%);
  background-position: calc(100% - 18px) calc(1em + 11px), calc(100% - 10px) calc(1em + 11px);
  background-repeat: no-repeat;
  background-size: 6px 6px, 9px 6px;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: none;
  border: 2px solid var(--color-blue-light);
  color: var(--color-black);
  font: inherit;
  text-align: center;
  outline: none;
  margin: 0;
  padding: 19px;
  line-height: 1.5em;
  transition: all .4s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.my-select::-ms-expand {
  display: none;
}

.my-select:hover {
  opacity: .8;
}

.steps {
  --z-10: 10;
  --z-5: 5;
  --z-0: -1;
}

.steps__body {
  height: 100%;
}

.steps-grid {
  grid-template-rows: 160px 180px 186px;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px 5%;
  display: grid;
}

@media (max-width: 1100px) {
  .steps-grid {
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
  }
}

@media (max-width: 480px) {
  .steps-grid {
    grid-template-rows: repeat(auto-fill, max-content);
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.steps-grid__item {
  border-radius: var(--border-radius);
  border: 1px solid #ededed;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 25px;
  font-weight: 500;
  transition: all .5s;
  display: flex;
  position: relative;
}

.steps-grid__item:hover {
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.steps-grid__item > span {
  text-align: right;
  color: #b5b8c0;
  z-index: var(--z-10);
  font-weight: 600;
}

.steps-grid__item > h4 {
  z-index: var(--z-10);
  font-size: 28px;
}

@media (max-width: 1100px) {
  .steps-grid__item > h4 {
    font-size: 24px;
  }
}

@media (max-width: 650px) {
  .steps-grid__item > h4 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .steps-grid__item > h4 {
    font-size: 18px;
  }
}

.steps-grid__item:after {
  content: "";
  width: 103%;
  height: 75%;
  border-radius: var(--border-radius);
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
  position: absolute;
  bottom: -2px;
  left: -5px;
}

.steps-grid__item:first-child {
  background: url("1.c0b90604.png") 0 / contain no-repeat;
}

.steps-grid__item:nth-child(2) {
  background: url("2.05a568a3.png") 0 / contain no-repeat;
}

.steps-grid__item:nth-child(3) {
  background: url("3.8c39f9e4.png") 0 / contain no-repeat;
}

.steps-grid__item:nth-child(4) {
  background: url("4.a1b9c5ea.png") center / contain no-repeat;
}

.steps-grid__item:nth-child(5) {
  background: url("5.83714bf9.png") center / contain no-repeat;
}

.steps-grid__item:nth-child(6) {
  background: url("6.3df949a4.png") 0 / contain no-repeat;
}

.steps-grid__item:nth-child(7) {
  background: url("7.ea44c1dd.png") 0 / contain no-repeat;
}

.steps-grid__item-spec {
  border-radius: var(--border-radius);
  z-index: 0;
  font-weight: 600;
  font-size: var(--fz-h4);
  background: #fafbff;
  border: 1px solid #ededed;
  grid-area: 3 / 2 / 4 / 4;
  padding: 20px 25px;
}

@media (max-width: 1100px) {
  .steps-grid__item-spec {
    text-align: center;
    grid-area: 5 / 1 / 6 / 3;
  }
}

@media (max-width: 480px) {
  .steps-grid__item-spec {
    grid-area: 8 / 1 / 9 / 1;
    padding: 20px 15px;
  }
}

.steps-grid__item-spec > h3 {
  margin-bottom: 15px;
}

.steps-grid__item-spec-btn {
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.steps-grid__item-spec-btn > p {
  font-weight: 600;
  font-size: var(--fz-h4);
  color: #b5b8c0;
  text-align: right;
  align-items: center;
  margin-left: 50px;
  display: flex;
}

.steps-grid__item-spec-btn > p:before {
  content: "";
  width: 70px;
  height: 10px;
  background: url("left.671f4e49.svg") center / contain no-repeat;
  margin-right: 35px;
  display: block;
}

@media (max-width: 650px) {
  .steps-grid__item-spec-btn > p:before {
    width: 20px;
    height: 16px;
    background: url("down.7b4489e9.svg") center / contain no-repeat;
    order: 2;
    margin-top: 10px;
    margin-right: 0;
  }
}

@media (max-width: 1100px) {
  .steps-grid__item-spec-btn > p {
    display: none;
  }
}

@media (max-width: 900px) {
  .steps-grid__item-spec-btn > p {
    width: 50%;
    display: flex;
  }
}

@media (max-width: 650px) {
  .steps-grid__item-spec-btn > p {
    text-align: center;
    flex-direction: column;
    order: -1;
    margin-bottom: 20px;
    margin-left: 0;
    font-size: 14px;
    font-weight: 400;
  }

  .steps-grid__item-spec-btn {
    width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .steps-grid__item {
    min-height: 130px;
  }
}

.photos__head span {
  color: #b5b8c0;
  line-height: 2;
  position: relative;
}

.photos__head span:before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("lupa.b7f30929.svg") center / cover no-repeat;
  position: absolute;
  top: 0;
  left: -30px;
}

.photos__gallery {
  justify-content: space-between;
  margin-bottom: 90px;
  display: flex;
}

@media (max-width: 768px) {
  .photos__gallery {
    flex-direction: column;
    margin-bottom: 50px;
  }
}

.photos__nav {
  width: 38%;
  margin-right: 50px;
}

@media (max-width: 768px) {
  .photos__nav {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .photos__nav {
    width: 100%;
  }
}

.photos__nav > ul {
  border-radius: var(--border-radius);
  border: 2px solid #f5f8ff;
  margin-bottom: 30px;
  padding: 20px;
}

.photos__nav > ul li {
  width: 100%;
}

.photos__nav > ul li:not(:last-child) {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .photos__nav > ul li:not(:last-child) {
    margin-bottom: 0;
  }
}

.photos__nav > ul li > input[type="radio"] {
  opacity: 0;
  width: 0;
  position: fixed;
}

.photos__nav > ul li > label {
  text-align: center;
  border-radius: var(--border-radius);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #f5f8ff;
  padding: 23px;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  transition: all .4s;
  display: block;
}

.photos__nav > ul li > label:hover {
  color: var(--color-white);
  background: linear-gradient(95.16deg, #e76c6c -10.96%, #ff7f7f 111.52%);
}

@media (max-width: 900px) {
  .photos__nav > ul li > label {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .photos__nav > ul li > label {
    font-size: 16px;
    display: none;
  }
}

.photos__nav > ul li > input[type="radio"]:checked + label {
  color: var(--color-white);
  background: linear-gradient(95.16deg, #e76c6c -10.96%, #ff7f7f 111.52%);
}

.photos__etc {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 900px) {
  .photos__etc {
    text-align: center;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .photos__etc {
    display: none;
  }
}

.photos__etc > img {
  object-fit: contain;
  width: 100px;
  margin-right: 10px;
}

@media (max-width: 900px) {
  .photos__etc > img {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.photos__etc > p {
  font-weight: 600;
}

.photos__etc-mob {
  display: none;
}

@media (max-width: 768px) {
  .photos__etc-mob {
    margin-bottom: 40px;
    display: flex;
  }
}

.photos__swiper {
  --size-prev-next: 50px;
  display: none;
}

.photos__swiper.active {
  width: 58%;
  height: 500px;
  display: block;
}

@media (max-width: 768px) {
  .photos__swiper.active {
    width: 100%;
    --size-prev-next: 36px;
  }
}

@media (max-width: 480px) {
  .photos__swiper.active {
    height: 300px;
  }
}

.photos__swiper .swiper {
  height: 380px;
  width: 100%;
  border-radius: var(--border-radius);
}

@media (max-width: 480px) {
  .photos__swiper .swiper {
    height: 200px;
  }
}

.photos__swiper .swiper-for {
  height: 100px;
  margin-top: 20px;
}

@media (max-width: 480px) {
  .photos__swiper .swiper-for {
    height: 80px;
  }
}

.photos__swiper .swiper-for .swiper-slide {
  border-radius: var(--border-radius);
  overflow: hidden;
}

.photos__swiper-next, .photos__swiper-prev {
  width: var(--size-prev-next);
  height: var(--size-prev-next);
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  outline: 0;
  position: absolute;
  top: 46%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, .1);
}

.photos__swiper-next {
  right: calc(.2 * var(--size-prev-next));
  background: url("слайдер.8c64a476.svg") center / contain no-repeat;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.photos__swiper-prev {
  left: calc(.6 * var(--size-prev-next));
  background: url("слайдер.8c64a476.svg") center / contain no-repeat;
}

.photos__swiper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#photos_2 img {
  object-fit: contain;
}

.pluses {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.pluses__item {
  border-radius: var(--border-radius);
  text-align: center;
  letter-spacing: .03em;
  color: #717070;
  background: linear-gradient(#fbfbfb 0%, #fafafa 100%);
  border: 2px solid #d8e5ff;
  flex-basis: 23%;
  padding: 15px;
  font-weight: 600;
  line-height: 1.5;
  transition: all .5s;
  box-shadow: 0 4px 20px rgba(148, 148, 148, .25);
}

.pluses__item:hover {
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .pluses__item {
    flex-basis: 47%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .pluses__item {
    flex-basis: 100%;
  }
}

.pluses__item > img {
  --pluses-h-img: 82px;
  --pluses-mb-img: 10px;
  object-fit: contain;
  height: var(--pluses-h-img);
  margin-bottom: var(--pluses-mb-img);
}

.pluses__item:first-child > img {
  height: calc(var(--pluses-h-img) * .8);
  margin-bottom: calc(var(--pluses-mb-img) * 2.2);
}

.my-select-photo {
  display: none;
}

@media (max-width: 768px) {
  .my-select-photo {
    display: inline-block;
  }
}

.my-select-photo {
  width: 100%;
  cursor: inherit;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: var(--border-radius);
  color: var(--color-white);
  font: inherit;
  text-align: center;
  background-color: #f07474;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #fff 50%), linear-gradient(135deg, #fff 50%, rgba(0, 0, 0, 0) 50%);
  background-position: calc(100% - 18px) calc(1em + 11px), calc(100% - 10px) calc(1em + 11px);
  background-repeat: no-repeat;
  background-size: 6px 6px, 9px 6px;
  border: none;
  outline: none;
  margin: 0;
  padding: 19px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
  transition: all .4s cubic-bezier(.4, 0, .2, 1);
}

.my-select-photo::-ms-expand {
  display: none;
}

.my-select-photo:hover {
  opacity: .8;
}

.studytime__body {
  max-width: 820px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.studytime__times {
  justify-content: center;
  margin-bottom: 60px;
  display: flex;
}

.studytime__times-item {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.studytime__times-item:first-child {
  margin-right: 200px;
}

@media (max-width: 768px) {
  .studytime__times-item:first-child {
    margin-right: 15%;
  }
}

@media (max-width: 650px) {
  .studytime__times-item:first-child {
    margin-right: 5%;
  }
}

@media (max-width: 480px) {
  .studytime__times-item:first-child {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.studytime__times-item > img {
  object-fir: contain;
  width: 150px;
  height: 157px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .studytime__times-item > img {
    width: 140px;
    height: 144px;
  }
}

@media (max-width: 480px) {
  .studytime__times-item > img {
    width: 130px;
    height: 133px;
  }
}

.studytime__times-item > h4 {
  font-weight: 400;
  font-size: var(--fz-h4);
  margin-bottom: 15px;
}

.studytime__times-item > span {
  color: #656d87;
}

@media (max-width: 768px) {
  .studytime__times {
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .studytime__times {
    flex-direction: column;
  }
}

.studytime__info {
  color: #5f6885;
  border: 1px solid #e9f0ff;
  border-radius: 15px;
  margin-bottom: 70px;
  padding: 24px 50px;
  font-weight: 600;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .studytime__info {
    margin-bottom: 30px;
  }
}

.sale__container {
  padding-top: var(--container-padding-h);
}

.sale__body {
  border-radius: calc(2 * var(--border-radius));
  height: 386px;
  z-index: 0;
  background: #fafbff;
  border: 4px solid #8cadef;
  padding: 60px;
  position: relative;
  overflow: hidden;
}

.sale__body > h2 {
  width: 61%;
  font-weight: 600;
  font-size: var(--fz-h2);
  z-index: 10;
  margin-bottom: 40px;
  line-height: 1.2;
  position: relative;
}

.sale__body > h2 > strong {
  color: #5d8ff1;
}

@media (max-width: 900px) {
  .sale__body > h2 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .sale__body > h2 {
    margin-bottom: 25px;
    font-size: 18px;
  }
}

.sale__body > img {
  object-fit: contain;
  object-position: bottom;
  height: 95%;
  width: 46%;
  position: absolute;
  bottom: -2px;
  right: 8px;
}

@media (max-width: 480px) {
  .sale__body > img {
    width: auto;
    height: 205px;
    position: relative;
    bottom: -20px;
    right: auto;
  }
}

@media (max-width: 1100px) {
  .sale__body {
    height: 420px;
    padding: 40px;
  }
}

@media (max-width: 900px) {
  .sale__body {
    height: 380px;
  }
}

@media (max-width: 650px) {
  .sale__body {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .sale__body {
    height: auto;
    text-align: center;
    padding: 15px 10px;
  }
}

.privilege__body {
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  display: grid;
}

@media (max-width: 1100px) {
  .privilege__body {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .privilege__body {
    grid-template-columns: 1fr;
  }
}

.privilege__item {
  border-radius: var(--border-radius);
  padding: 20px 40px;
  transition: all .5s;
}

.privilege__item:hover {
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.privilege__item:nth-child(2n) {
  background: #e3e8ff;
}

.privilege__item > img {
  object-fit: contain;
  height: 110px;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .privilege__item > img {
    height: 90px;
  }
}

.privilege__item > h4 {
  font-weight: 600;
  font-size: var(--fz-h4);
  margin-bottom: 10px;
}

.privilege__item > p {
  font-weight: 400;
}

@media (max-width: 900px) {
  .privilege__item {
    padding: 20px 26px;
  }
}

@media (max-width: 650px) {
  .privilege__item {
    text-align: center;
  }
}

.maps__container {
  padding-bottom: var(--container-padding-h);
}

.maps__body {
  position: relative;
}

.maps__map {
  height: 496px;
}

.maps__map > iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 650px) {
  .maps__map {
    height: 350px;
  }
}

@media (max-width: 480px) {
  .maps__map {
    height: 190px;
  }
}

.infomap {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid #ededed;
  padding: 40px 70px;
  line-height: 1.4;
  position: absolute;
  top: 48px;
  left: 15%;
}

.infomap > h4 {
  font-weight: 600;
  font-size: var(--fz-h4);
  text-align: center;
  margin-bottom: 20px;
}

.infomap__item {
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

.infomap__item:last-child {
  margin-bottom: 0;
  line-height: 1.1;
}

@media (max-width: 480px) {
  .infomap__item {
    width: 190px;
    margin-bottom: 15px;
  }
}

.infomap__text {
  margin-left: 15px;
}

@media (max-width: 480px) {
  .infomap__text {
    margin-left: 30px;
  }
}

@media (max-width: 900px) {
  .infomap {
    padding: 30px 50px;
  }
}

@media (max-width: 768px) {
  .infomap {
    left: 3%;
  }
}

@media (max-width: 650px) {
  .infomap {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    display: flex;
    position: relative;
    top: auto;
    left: auto;
  }
}

@media (max-width: 480px) {
  .infomap {
    margin-left: var(--container-padding-w);
    margin-right: var(--container-padding-w);
  }
}

.questions {
  flex-wrap: wrap;
  gap: 10px 4%;
  display: flex;
}

.questions__item {
  height: min-content;
  font-size: var(--fz);
  border-radius: var(--border-radius);
  cursor: pointer;
  background: #fafbff;
  flex-direction: column;
  flex-basis: 48%;
  justify-content: space-between;
  padding: 25px;
  font-weight: 400;
  line-height: 1.5;
  transition: all .5s;
  display: flex;
}

.questions__item:hover {
  background: #e0e7ff;
}

@media (max-width: 768px) {
  .questions__item {
    flex-basis: 100%;
  }
}

.questions__title {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.questions__title:after {
  content: "";
  width: 45px;
  height: 45px;
  background: url("faq.8a1af1ca.svg") center / contain no-repeat;
  margin-left: 5px;
  display: inline-block;
}

.questions__answer {
  height: 0;
  transition: height .5s;
  overflow: hidden;
}

@media (max-width: 768px) {
  .questions {
    gap: 15px;
  }
}

.footer__body {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 480px) {
  .footer__body {
    flex-direction: column;
  }
}

.footer__links {
  margin-right: 8%;
  font-size: 20px;
  font-weight: 600;
}

.footer__links > img {
  object-fit: contain;
  width: 150px;
  height: 90px;
  margin-bottom: 15px;
}

@media (max-width: 480px) {
  .footer__links {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    margin-right: 0;
    display: flex;
  }
}

.footer__messengers > h4 {
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  .footer__messengers {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.footer__nav {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.footer__nav > ul:not(:last-child) {
  margin-right: 50px;
}

@media (max-width: 480px) {
  .footer__nav > ul:not(:last-child) {
    margin-right: 0;
  }
}

.footer__nav > ul > li {
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.5;
}

.footer__nav > ul > li > a {
  transition: all .5s;
}

.footer__nav > ul > li > a:hover {
  color: var(--color-gray);
}

.footer__nav > ul > li:first-child {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 480px) {
  .footer__nav {
    text-align: center;
    flex-flow: column;
    align-items: center;
  }
}

.footer__madeby {
  color: #b5b8c0;
  margin-top: 50px;
  font-weight: 400;
}

@media (max-width: 480px) {
  .footer__madeby {
    text-align: center;
  }
}

.footer__madeby > a {
  transition: all .5s;
}

.footer__madeby > a:hover {
  color: var(--color-black);
}



.btn {
  --br: 8px;
  z-index: 10;
  position: relative;
}

.btn__container {
  height: auto;
  border-radius: var(--br);
  background: linear-gradient(273.8deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 42.88%, rgba(255, 255, 255, 0) 99.19%), linear-gradient(263.81deg, rgba(230, 38, 38, .8) 10.32%, rgba(236, 75, 75, .8) 98.17%);
  padding: 4px;
  transition: all .5s;
  position: relative;
  box-shadow: inset 0 2px 15px rgba(255, 255, 255, .11), inset 19px 0 26px rgba(255, 255, 255, .11);
}

.btn__container:hover {
  background: linear-gradient(10deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 42.88%, rgba(255, 255, 255, 0) 99.19%), linear-gradient(10deg, rgba(230, 38, 38, .8) 10.32%, rgba(236, 75, 75, .8) 98.17%);
}

.btn__container:before {
  content: "";
  width: 100%;
  height: 105%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: var(--br);
  z-index: -1;
  background: linear-gradient(0deg, #b34444, #b34444), linear-gradient(263.81deg, rgba(188, 112, 112, .8) 10.32%, rgba(212, 129, 129, .8) 98.17%);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 2px 15px rgba(255, 255, 255, .11), inset 19px 0 26px rgba(255, 255, 255, .11);
}

.btn__content {
  min-width: 300px;
  width: max-content;
  letter-spacing: .05em;
  color: var(--color-white);
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  font-size: var(--fz-btn);
  z-index: 5;
  align-items: center;
  padding: 15px 35px;
  display: flex;
  position: relative;
}

.btn__content > img {
  margin-right: 20px;
}

@media (max-width: 900px) {
  .btn__content > img {
    width: 28px;
  }
}

.btn__content:before {
  content: "";
  border-radius: var(--br);
  -webkit-mask-composite: xor;
  background: linear-gradient(96deg, #fff, rgba(255, 255, 255, 0)) border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-mask-image: linear-gradient(#fff 0, #fff 0), linear-gradient(#fff 0, #fff 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

@media (max-width: 900px) {
  .btn__content {
    min-width: 260px;
    padding: 12px 24px;
  }
}

.btn > .flare {
  height: 100%;
  width: 45px;
  background: linear-gradient(90deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
  animation: 4s linear 5s infinite flareAnimation;
  position: absolute;
  top: 0;
  left: -150%;
  -ms-transform: skewX(-45deg);
  transform: skewX(-45deg);
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }

  50% {
    left: 150%;
  }

  100% {
    left: 150%;
  }
}

.cookie {
  z-index: 999999;
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  display: none;
  position: fixed;
  bottom: 3%;
  left: 0;
  right: 0;
}

.cookie__wrapper {
  border-radius: var(--border-radius);
  text-align: center;
  background-color: rgba(255, 255, 255, .9);
  justify-content: center;
  padding: 10px 25px;
  font-size: 12px;
  display: flex;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, .1), 0 20px 50px 15px rgba(0, 0, 0, .1);
}

.cookie__wrapper > p {
  margin-right: 15px;
}

.cookie__wrapper > p > a {
  text-decoration: underline;
}

@media (max-width: 650px) {
  .cookie__wrapper {
    padding: 10px;
  }
}

.cookie-close {
  cursor: pointer;
  height: 100%;
}

.cookie-close > svg {
  width: 36px;
  height: 36px;
}

.input {
  font-size: var(--fz-btn);
  color: var(--color-gray);
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 30px 20px;
  display: inline-block;
}

.input::placeholder {
  color: var(--color-gray);
}

.input:hover {
  cursor: text;
}

@media (max-width: 900px) {
  .input {
    padding: 20px 10px;
  }
}

.messengers {
  display: flex;
}

.messengers__item {
  width: 47px;
  height: 32px;
  transition: all .4s;
}

.messengers__item > a {
  width: 100%;
  height: 100%;
  display: block;
}

.messengers__item > a > img {
  object-fit: contain;
  width: 100%;
}

.messengers__item:hover {
  -ms-transform: scale(.9);
  transform: scale(.9);
}

.modal {
  z-index: 99999;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .8);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal:after {
  content: "";
  width: 30px;
  height: 30px;
  z-index: 100;
  background: url("close.7374cde7.svg") 50% / contain no-repeat;
  display: inline-block;
  position: absolute;
  top: 2%;
  right: 5%;
}

.modal__container {
  width: 40vw;
  max-width: 650px;
  height: auto;
  border-radius: calc(var(--border-radius) * 2);
  cursor: auto;
  background-color: #fefefe;
  border: 1px solid rgba(122, 118, 229, .5);
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .modal__container {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .modal__container {
    width: 90%;
  }
}

.modal__body {
  height: 100%;
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, .08);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 30px;
  display: flex;
  position: relative;
}

@media (max-width: 650px) {
  .modal__body {
    padding: 25px 15px;
  }
}

.modal__head {
  color: var(--color-blue);
}

.modal__head > h2 {
  font-size: var(--fz-h2);
  font-weight: 600;
}

.modal__head > p {
  margin-top: 20px;
}

.modal__main {
  width: 100%;
  color: var(--color-blue);
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.modal__main > hr {
  border: 0;
  border-top: 1px solid var(--color-blue);
  width: 80%;
  height: 2px;
  margin: 0;
}

.modal__main > p {
  font-size: var(--fz-h4);
  text-align: center;
  font-weight: 400;
}

.modal__form {
  width: min-content;
}

.modal__form > .input {
  width: 88%;
  margin-bottom: 20px;
}

.modal__form > p {
  color: silver;
  margin-top: 20px;
  font-size: 14px;
}

.modal__form > p > a {
  text-decoration: underline;
}

@media (max-width: 650px) {
  .modal__form > p {
    font-size: 12px;
  }
}



html {
  background-color: var(--color-white);
  color: var(--color-black);
  scroll-behavior: smooth;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
}

@media (max-width: 650px) {
  html {
    font-size: 14px;
  }
}

b {
  font-weight: 600;
}

:root {
  --color-white: #fff;
  --color-black: #424242;
  --color-gray: #9f9fa1;
  --color-blue: #343d58;
  --color-blue-light: #5d8ff1;
  --color-blue-f: #5f6885;
  --fz: 14px;
  --fz-btn: 16px;
  --fz-h4: 20px;
  --fz-h2: 36px;
  --content-width: 1200px;
  --container-padding-h: 80px;
  --container-padding-w: 30px;
  --border-radius: 10px;
}

@media (max-width: 900px) {
  :root {
    --fz-h2: 28px;
    --fz-btn: 14px;
    --container-padding-h: 60px;
  }
}

@media (max-width: 768px) {
  :root {
    --fz-btn: 14px;
    --fz-h4: 18px;
    --container-padding-w: 20px;
  }
}

@media (max-width: 650px) {
  :root {
    --container-padding-w: 10px;
    --container-padding-h: 50px;
  }
}

@media (max-width: 480px) {
  :root {
    --fz-h2: 24px;
  }
}

.container {
  max-width: var(--content-width);
  padding-left: var(--container-padding-w);
  padding-right: var(--container-padding-w);
  padding-bottom: var(--container-padding-h);
  margin-left: auto;
  margin-right: auto;
}

.head {
  text-align: center;
  padding-top: var(--container-padding-h);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
}

.head > h2 {
  font-weight: 600;
  font-size: var(--fz-h2);
  letter-spacing: .05em;
  line-height: 1.5;
}

.head > p {
  margin-top: 20px;
}

.head_white {
  color: #fff;
}

[data-animate-on-scroll] {
  opacity: 0;
}

[data-animate-on-scroll="animated"] {
  opacity: 1;
  animation: 2s wipe-enter;
}

@keyframes wipe-enter {
  0% {
    opacity: 0;
    -ms-transform: translate(0, -20px);
    transform: translate(0, -20px);
  }

  100% {
    opacity: 1;
    -ms-transform: translate(0);
    transform: translate(0);
  }
}

/*# sourceMappingURL=index.6e22abae.css.map */
