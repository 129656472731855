.btn {
  --br: 8px;
  position: relative;
  z-index: 10;

  &__container {
    position: relative;
    height: auto;
    padding: 4px;
    background: 
      linear-gradient(273.8deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 42.88%, rgba(255, 255, 255, 0) 99.19%), 
      linear-gradient(263.81deg, rgba(230, 38, 38, 0.8) 10.32%, rgba(236, 75, 75, 0.8) 98.17%);
    box-shadow: inset 0px 2px 15px rgba(255, 255, 255, 0.11), inset 19px 0px 26px rgba(255, 255, 255, 0.11);
    border-radius: var(--br);
    transition: all .5s ease;

    &:hover {
      background: 
        linear-gradient(10deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 42.88%, rgba(255, 255, 255, 0) 99.19%), 
        linear-gradient(10deg, rgba(230, 38, 38, 0.8) 10.32%, rgba(236, 75, 75, 0.8) 98.17%);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 105%;
      background: linear-gradient(0deg, #b34444, #b34444), linear-gradient(263.81deg, rgba(188, 112, 112, 0.8) 10.32%, rgba(212, 129, 129, 0.8) 98.17%);
      box-shadow: inset 0px 2px 15px rgba(255, 255, 255, 0.11), inset 19px 0px 26px rgba(255, 255, 255, 0.11);
      backdrop-filter: blur(5px);
      border-radius: var(--br);
      z-index: -1;
    }
  }

  &__content {
    position: relative;
    min-width: 300px;
    width: max-content;
    padding: 15px 35px;
    letter-spacing: 0.05em;
    font-weight: 600;
    color: var(--color-white);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: var(--fz-btn);
    z-index: 5;

    & > img {
      margin-right: 20px;

      @media (--lg) {
        width: 28px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: var(--br);
      border: 1px solid transparent;
      background: linear-gradient(96deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) border-box;
      -webkit-mask: /*4*/
       linear-gradient(#fff 0 0) padding-box, 
       linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor; /*5'*/
              mask-composite: exclude; /*5*/
    }

    @media (--lg) {
      padding: 12px 24px;
      min-width: 260px;
    }
  }

  & > .flare {
      position: absolute;
      top: 0;
      height: 100%;
      width: 45px;
      transform: skewX(-45deg);
      animation: flareAnimation;
      left: -150%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
      animation: flareAnimation 4s linear 5s infinite;
  }
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  50% {
    left: 150%;
  }
  100% {
    left: 150%;
  }
}