.navpanel {
  --h_nav: 72px;
  @media (--sm) {
    --h_nav: 60px;
  }

  &__container {
    max-width: var(--content-width);
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--container-padding-w);
    padding-right: var(--container-padding-w);
    padding-top: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--h_nav);
  }
  &__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 1;
    font-weight: 600;
    font-size: 20px;

    & img {
      object-fit: contain;
      width: 170px;
      height: var(--h_nav);
      margin-bottom: 5px;

      @media (--xl) {
        width: 130px;
      }
      @media (--xs) {
        width: 110px;
      }
    }
  }
  &__address {
    display: flex;
    height: 100%;
    
    & > img {
      width: 42px;
      height: 42px;
      margin-right: 15px;
    }
    &-right {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      & > span {
        color: var(--color-gray);
        font-weight: 600;
        font-size: 10px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
      & > p {
        font-weight: 600;
      }
      & > a {
        display: inline;
        width: fit-content;
        margin-top: 5px;
        color: var(--color-gray);
        font-size: 11px;
        border-bottom: 1px dashed var(--color-gray);
        transition: all .3s ease-in;

        &:hover {
          color: var(--color-black);
          border-bottom: 1px dashed var(--color-black);
        }
      }
    }
    
    @media (--xl) {
      display: none;
    }
  }
  &__mess {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span {
      font-size: 12px;
      line-height: 1.5;
      &::before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: rgb(21, 197, 21);
        border-radius: 50%;
        margin-right: 5px;
      }
      @media (--xs) {
        position: absolute;
        top: 6px;
      }
    }
    @media (--sm) {
      order: 1;
    }
    @media (--xs) {
      order: 0;
    }
  }
  &__phone {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > span {
      color: var(--color-gray);
      font-weight: 600;
      font-size: 10px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 9px;
    }
    & > a {
      font-weight: 600;
      font-size: var(--fz-h4);
      transition: all .4s ease;

      &:hover {
        opacity: 0.9;
      }
    }

    @media (--xs) {
      display: none;
    }
  }
  &__cta {
    display: flex;
    align-items: center;
    color: var(--color-white);
    font-weight: 600;
    font-size: 16px;
    padding: 12px 24px;
    background: #e84b4b;
    border-radius: 5px;
    line-height: 1;
    transition: all .5s ease;
    & > img {
      margin-right: 10px;
    }
    
    &:hover {
      transform: scale(0.95);
    }

    @media (--md) {
      font-size: 14px;
      padding: 8px 16px;
    }
    @media (--sm) {
      display: none;
    }
  }
  /* логика адаптива для навигации */
  &__mobile {
    --w_burger: 47px;
    --h_burger: 34px;
    display: none;

    @media (--xs) {
      display: block;
      width: var(--w_burger);
    }
  }
  &__burger {
    display: flex;
    width: var(--w_burger);
    height: var(--h_burger);

    & > input[type=checkbox] {
      display: none;

      &+label {
        width: var(--w_burger);
        height: var(--h_burger);
        cursor: pointer;
        transition: .4s;
      }
    }
    & img {
      object-fit: contain;
      width: 100%;
    }
  }
  &__menu {
    display: none;

    @media (--xs) {
      position: absolute;
      top: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 0;
      width: 100%;
      opacity: 1;
      background-color: #f5f5f6;
      border-radius: 5px;
      overflow: hidden;
      transition: .5s;
      transition-delay: .2s;
      z-index: 100;
    }

    & > p {
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    & > ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      margin-bottom: 25px;

      & > li > a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 5px 10px;
        font-weight: 400;
        font-size: var(--fz);
        border-radius: var(--border-radius);
        transition: all .4s ease;

        &:hover, &:focus {
          color: var(--color-blue-light);
        }
      }
    }
    & > .navpanel__phone {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      margin-bottom: 15px;
    }
    & > .navpanel__cta {
      display: flex;
    }
  }
}