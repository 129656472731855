.input {
  display: inline-block;
  padding: 30px 20px;
  font-size: var(--fz-btn);
  color: var(--color-gray);
  border: 1px solid #BBB;
  border-radius: 8px;

  &::placeholder {
    color: var(--color-gray);
  }

  &:hover {
    cursor: text;
  }

  @media (--lg) {
    padding: 20px 10px;
  }
}