.cookie {
  display: none;
  left: 0;
  right: 0;
  bottom: 3%;
  position: fixed;
  z-index: 999999;
  max-width: var(--content-width);
  margin-right: auto;
  margin-left: auto;
  padding: 0 10px;

  &__wrapper {
    display: flex;
    justify-content: center;
    padding: 10px 25px;
    background-color: rgba(255,255,255, 0.9);
    border-radius: var(--border-radius);
    text-align: center;
    font-size: 12px;
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1), 0px 20px 50px 15px rgba(0, 0, 0, 0.1);

    & > p {
      margin-right: 15px;

      & > a {
        text-decoration: underline;
      }
    }
    @media (--sm) {
      padding: 10px;
    }
  }


  &-close {
    cursor: pointer;
    height: 100%;

    & > svg {
      width: 36px;
      height: 36px;
    }
  }
}