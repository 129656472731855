.photos {
  &__head {
    & span {
      position: relative;
      line-height: 2;
      color: #B5B8C0;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: -30px;
        width: 20px;
        height: 20px;
        background: url(../img/lupa.svg) center/cover no-repeat;
      }
    }
  }
  &__gallery {
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    @media (--md) {
      flex-direction: column;
      margin-bottom: 50px;
    }
  }
  &__nav {
    width: 38%;
    margin-right: 50px;
    @media (--md) {
      width: 60%;
      margin-right: auto;
      margin-left: auto;
    }
    @media (--xs) {
      width: 100%;
    }

    & > ul {
      padding: 20px;
      border: 2px solid #F5F8FF;
      border-radius: var(--border-radius);
      margin-bottom: 30px;
      & li {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 15px;
          @media (--md) {
            margin-bottom: 0;
          }
        }

        & > input[type=radio] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        & > label {
          display: block;
          padding: 23px;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          border: 2px solid #F5F8FF;
          border-radius: var(--border-radius);
          cursor: pointer;
          user-select: none;
          transition: all .4s ease;
          &:hover {
            color: var(--color-white);
            background: linear-gradient(95.16deg, #e76c6c -10.96%, #ff7f7f 111.52%);
          }

          @media (--lg) {
            padding: 20px;
          }
          @media (--md) {
            display: none;
            font-size: 16px;
          }
        }
        /* Checked */
        & > input[type=radio]:checked + label {
          color: var(--color-white);
          background: linear-gradient(95.16deg, #e76c6c -10.96%, #ff7f7f 111.52%);
        }
      }
    }
  }
  &__etc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (--lg) {
      flex-direction: column;
      text-align: center;
    }
    @media (--md) {
      display: none;
    }

    & > img {
     object-fit: contain;
     width: 100px; 
     margin-right: 10px;
     @media (--lg) {
      margin-right: 0;
      margin-bottom: 20px;
    }
    }
    & > p{
      font-weight: 600;
    }
    &-mob {
      display: none;
      @media (--md) {
        display: flex;
        margin-bottom: 40px;
      }
    }
  }
  &__swiper {
    display: none;
    --size-prev-next: 50px;
    &.active {
      display: block;
      width: 58%;
      height: 500px;
      @media (--md) {
        width: 100%;
        --size-prev-next: 36px;
      }
      @media (--xs) {
        height: 300px;
      };
    }

    .swiper {
      height: 380px;
      width: 100%;
      border-radius: var(--border-radius);
      @media (--xs) {
        height: 200px;
      }
    }
    .swiper-for {
      height: 100px;
      margin-top: 20px;
      @media (--xs) {
        height: 80px;
      }

      & .swiper-slide {
        overflow: hidden;
        border-radius: var(--border-radius);
      }
    }
    &-next, &-prev {
      position: absolute;
      top: 46%;
      transform: translate(-50%,0);
      width: var(--size-prev-next);
      height: var(--size-prev-next);
      box-shadow: 0 2px 5px 1px rgb(0 0 0 / 10%);
      border-radius: 50%;
      outline: 0;
      z-index: 10;
      cursor: pointer;
    }
    &-next {
      right: calc(0.2*var(--size-prev-next));
      background: url(../img/photos/слайдер.svg) center/contain no-repeat;
      transform: rotate(180deg);
    }
    &-prev {
      left: calc(0.6*var(--size-prev-next));
      background: url(../img/photos/слайдер.svg) center/contain no-repeat;
    }
    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

#photos_2 {
  & img {
    object-fit: contain;
  }
}

.pluses {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__item {
    flex-basis: 23%;
    background: linear-gradient(180deg, #FBFBFB 0%, #FAFAFA 100%);
    border: 2px solid #D8E5FF;
    box-shadow: 0px 4px 20px rgba(148, 148, 148, 0.25);
    border-radius: var(--border-radius);
    padding: 15px;

    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.03em;

    color: #717070;
    transition: all .5s ease;
    &:hover {
      transform: scale(1.05);
    }

    @media (--md) {
      flex-basis: 47%;
      margin-bottom: 20px;
    }
    @media (--xs) {
      flex-basis: 100%;
    }

    & > img {
      --pluses-h-img: 82px;
      --pluses-mb-img: 10px;
      object-fit: contain;
      height: var(--pluses-h-img);
      margin-bottom: var(--pluses-mb-img);
    }
    &:first-child > img {
      height: calc(var(--pluses-h-img) * 0.8);
      margin-bottom: calc(var(--pluses-mb-img) * 2.2);
    }
  }
}

.my-select-photo {
  display: none;
  @media (--md) {
    display: inline-block;
  }

  /* reset */
  border: none;
  margin: 0;
  width: 100%;
  outline: none;
  cursor: inherit;
  appearance: none;
  /* style */
  background-image: linear-gradient(45deg, transparent 50%, #FFF 50%),
    linear-gradient(135deg, #FFF 50%, transparent 50%);
  background-position: calc(100% - 18px) calc(1em + 11px),
    calc(100% - 10px) calc(1em + 11px);
  background-size: 6px 6px, 9px 6px;
  background-repeat: no-repeat;
  background-color: #F07474;
  border-radius: var(--border-radius);
  color: var(--color-white);
  font: inherit;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  line-height: 1.5em;
  padding: 19px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &::-ms-expand {
    display: none;
  }
  &:hover {
    opacity: 0.8;
  }
}