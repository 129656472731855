.header {
  &__container {
    padding-bottom: var(--container-padding-h);
  }

  &__nav {
    margin-top: 25px;
    background: #F9FAFC;
    & > ul {
      display: flex;
      flex-wrap: wrap;
      max-width: var(--content-width);
      margin-right: auto;
      margin-left: auto;
      padding: 20px var(--container-padding-w);
      & > li {
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.05em;
        color: #9F9FA1;
        & > a {
          transition: all .5s ease;

          &:hover {
            color: var(--color-black);
          }
        }

        &:not(:last-child) {
          margin-right: 60px;

          @media (--md) {
            margin-right: 30px;
          }
        }
      } 
    }

    @media (--xs) {
      display: none;
    }
  }
  &__main {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../img/header/bg.png) center/cover no-repeat;
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -100px;
      left: 0;
      width: 100%;
      height: 270px;
      background: url(../img/header/smoke.png) center/contain repeat-x;
      z-index: 0;
      @media (--md) {
        background: url(../img/header/smoke.png) center/cover repeat-x;
        height: 190px;
        bottom: -50px;
      }
      @media (--sm) {
        height: 250px;
        bottom: 0;
      }
      @media (--xs) {
        height: 200px;
        bottom: 0;
      }
    }

    &-wrapper {
      position: relative;
      width: 100%;
      max-width: var(--content-width);
      margin-right: auto;
      margin-left: auto;
      padding-left: var(--container-padding-w);
      padding-right: var(--container-padding-w);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (--sm) {
        flex-direction: column;
      }
    }
    &-left {
      flex-basis: 50%;
      margin-top: 80px;
      margin-bottom: 120px;
      & > h1 {
        font-weight: 600;
        font-size: var(--fz-h2);
        line-height: 1.1;
        margin-bottom: 40px;

        @media (--sm) {
          margin-bottom: 0;
        }
        @media (--xs) {
          text-align: center;
        }

        & > strong {
          position: relative;
          transform: rotate(-3deg);
          display: inline-block;
          padding: 2px 8px;
          margin-left: 10px;
          color: #fff;
          font-size: .8em;
          box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: inherit;
            width: 100%;
            height: 100%;
            background: #e84b4b;
            border-radius: 5px;
            z-index: -1;
          }

        }
      }
      & > .btn {
        @media (--sm) {
          display: none;
        }
      }

      @media (--lg) {
        margin-bottom: 80px;
      }
      @media (--md) {
        flex-basis: 60%;
      }
      @media (--sm) {
        flex-basis: 100%;
        margin-top: 60px;
        margin-bottom: 60px;
      }

    }
    &-right {
      position: absolute;
      top: 0;
      right: 0px;
      max-width: 820px;
      flex-basis: 100%;
      width: 70%;
      & > img {
        object-fit: contain;
        width: 100%;
      }
      & > .btn {
        display: none;
        @media (--sm) {
          display: inline-block;
        }
      }

      @media (--xl) {
        width: 65%;
        top: 80px;
      }
      @media (--lg) {
        top: auto;
        bottom: 10px;
      }
      @media (--md) {
        bottom: 40px;
      }
      @media (--sm) {
        position: relative;
        width: 100%;
        text-align: center;
      }
    }
  }

  &__utp {
    position: relative;

    & > ul {
      max-width: var(--content-width);
      margin-right: auto;
      margin-left: auto;
      padding-left: var(--container-padding-w);
      padding-right: var(--container-padding-w);

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
      z-index: 10;

      & > li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 19px 25px;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        border-radius: var(--border-radius);

        & > img {
          object-fit: contain;
          height: 62px;
          margin-bottom: 15px;

          @media (--md) {
            height: 50px;
          }
        }
        & > span {
          font-weight: 600;
          font-size: var(--fz-h4);
          margin-bottom: 10px;
        }
        & > p {
          font-weight: 400;
        }

        @media (--md) {
          padding: 18px;
        }
        @media (--sm) {
          align-items: center;
          text-align: center;
        }
      }

      @media (--sm) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 195px;
      background: #FAFBFF;
      z-index: -1;

      @media (--sm) {
        top: auto;
        bottom: -60px;
        height: 90px;
      }
    }
  }
}