.messengers {
  display: flex;
  
  &__item {
    width: 47px;
    height: 32px;
    transition: all .4s ease;

    & > a {
      display: block;
      width: 100%;
      height: 100%;

      & > img {
        object-fit: contain;
        width: 100%;
      }
    }
    &:hover {
      transform: scale(0.9);
    }
  }
}